import * as Video from 'twilio-video'

import React, {useEffect, useContext, useState} from 'react'

import Layout from '../components/layout'
import UserContext from '../context/UserContext'
import {navigate} from 'gatsby'
import {api} from '@cg-squad/ui-components/dist/utils'
import '../css/pages/chat.scss'
import Popup from '../components/ui/popup/Popup'

const RoomPage = () => {
  const [findingRoom, setFindingRoom] = useState(true);
  const [room, setRoom] = useState(null);
  const [openEndCallPopup, setOpenEndCallPopup] = useState(false);
  const [openSwitchRoomPopup, setOpenSwitchRoomPopup] = useState(false);

  const userContext = useContext(UserContext);
  let isStartRoomInProgress = false;

  useEffect(() => {
    /*if (!userContext.user) {
      return navigate('/meet')
    }
    setTimeout(() => {
      startRoom();
    })*/
  }, [userContext.user])

  const joinVideoRoom = async (roomName, token) => {

    // join the video room with the Access Token and the given room name
    const room = await Video.connect(token, {
      room: roomName
    }).catch(e => {
      throw e;
    })

    // FIXME what if 2 users get the same room name that has already 3 users (race condition)?
    await api.post('chat-room/join', {roomName})
    return room
  }

  const startRoom = async (skipRoom) => {
    if (isStartRoomInProgress) {
      return;
    }
    isStartRoomInProgress = true;
    setFindingRoom(true);
    let url = 'chat-room';
    if (skipRoom) {
      url += `?skip=${skipRoom}`
    }
    // fetch an Access Token from the join-room route
    const response = await api.get(url);
    const {token, roomName} = response

    try {
      // join the video room with the token
      const room = await joinVideoRoom(roomName, token);
      setRoom(room)
      setFindingRoom(false)
      console.log(room)
      // render the local and remote participants' video and audio tracks
      handleConnectedParticipant(room.localParticipant)
      room.participants.forEach(handleConnectedParticipant)
      room.on('participantConnected', handleConnectedParticipant)
      // handle cleanup when a participant disconnects
      room.on('participantDisconnected', handleDisconnectedParticipant)
      window.addEventListener('beforeunload', handleDisconnect)
    } catch (e) {
      if (e && e.code && e.code == 53105) {
        // retry with other room
        switchRoom(roomName);
      }
      console.log(e);
    } finally {
      isStartRoomInProgress = false;
    }
  }

  const handleConnectedParticipant = (participant) => {
    if (document.getElementById(participant.identity)) {
      return
    }
    // create a div for this participant's tracks
    const participantDiv = document.createElement('div')
    participantDiv.setAttribute('id', participant.identity)
    const container = document.getElementById('video-container')
    const targetDiv = Array.from(container.childNodes)
      .find(item => item.innerHTML == '')
    targetDiv.appendChild(participantDiv)

    // iterate through the participant's published tracks and
    // call `handleTrackPublication` on them
    participant.tracks.forEach((trackPublication) => {
      handleTrackPublication(trackPublication, participant)
    })

    // listen for any new track publications
    participant.on('trackPublished', handleTrackPublication)
  }

  const handleTrackPublication = (trackPublication, participant) => {
    function displayTrack (track) {
      // append this track to the participant's div and render it on the page
      const participantDiv = document.getElementById(participant.identity)
      // track.attach creates an HTMLVideoElement or HTMLAudioElement
      // (depending on the type of track) and adds the video or audio stream
      participantDiv.append(track.attach())
    }

    // check if the trackPublication contains a `track` attribute. If it does,
    // we are subscribed to this track. If not, we are not subscribed.
    if (trackPublication.track) {
      displayTrack(trackPublication.track)
    }

    // listen for any new subscriptions to this track publication
    trackPublication.on('subscribed', displayTrack)
  }

  const handleDisconnect = async () => {
    await api.post('chat-room/leave', {roomName: room.name})

    room.disconnect()
  }

  const handleDisconnectedParticipant = (participant) => {
    // stop listening for this participant
    participant.removeAllListeners()
    // remove this participant's div from the page
    const participantDiv = document.getElementById(participant.identity)
    participantDiv.remove()
  }

  const getParticipants = () => {
    const arr = Array.from({length: 4}, (_, i) => i + 1)
    const renderedParticipants = arr.map((item) => <div id={`participant-${item}`}
                                                        key={`participant-${item}`}></div>)
    return renderedParticipants
  }

  const toggleMic = () => {
    room.localParticipant.audioTracks.forEach(
      publication => publication.track.isEnabled ? publication.track.disable() : publication.track.enable()
    )
  }

  const toggleCamera = () => {
    room.localParticipant.videoTracks.forEach(
      publication => publication.track.isEnabled ? publication.track.disable() : publication.track.enable()
    )
  }

  const endCall = async () => {
    await handleDisconnect()
    navigate('/')
  }

  const switchRoom = async (roomName) => {
    const currentRoomName = roomName || room.name;
    if (room) {
      await handleDisconnect();
    }
    startRoom(currentRoomName);
  }

  return (
    <Layout>
      <main className="wrapper main-data-container" role="main" data-datocms-noindex>
      {findingRoom
        ?
        <div className={"bg-right-bottom lg:bg-center bg-size-700 lg:bg-cover bg-no-repeat bg-room-waiting h-[630px] flex lg:items-center"}>
          <div className={"text-center max-w-[395px] lg:ml-32 mt-12 lg:mt-0"}>
            <div className={"mb-3"}><img src={"/images/age-meet/dot.png"}/></div>
            <h4 className={"leading-snug font-bold text-lg"}>We are searching for an appropriate room for you </h4>
            <p className={"text-lg"}>Let's find you a perfect seat at a table of people you might like</p>
            <div className={"text-denim text-lg flex items-center justify-center"}>
              Room search in progress
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        :
        <>
          <div id="video-container" className="grid grid-cols-2">
            {getParticipants()}
          </div>
          <div className="mx-auto bg-black" id="chat-controls">
            <div className="relative flex h-[95px] items-center justify-center">
              <div className={'flex justify-center h-[68px]'}>
                <div className={'font-bold flex flex-col items-center mx-10 cursor-pointer'}
                     onClick={() => {setOpenSwitchRoomPopup(true);}}>
                  <img src={'/images/age-meet/icons/flip-camera.svg'} className={'my-2'} width={32}
                       height={32} alt={'camera'}/>
                  <span className={'text-13 text-white font-light'}>Switch Room</span>
                </div>
                <div className={'font-bold flex flex-col items-center mr-10 cursor-pointer'}
                     onClick={toggleCamera}>
                  <img src={'/images/age-meet/icons/video.svg'} className={'my-2'} width={32}
                       height={32} alt={'camera'}/>
                  <span className={'text-13 text-white font-light'}>Camera</span>
                </div>
                <div className={'cursor-pointer'} onClick={() => {setOpenEndCallPopup(true)}}>
                  <img src={'/images/age-meet/icons/end-call.svg'} width={48} height={48}
                       alt={'end-call'}/>
                </div>
                <div className={'font-bold flex flex-col items-center mx-10 cursor-pointer'}
                     onClick={toggleMic}>
                  <img src={'/images/age-meet/icons/mic.svg'} className={'my-2'} width={32}
                       height={32} alt={'camera'}/>
                  <span className={'text-13 text-white font-light'}>Mic</span>
                </div>
              </div>
            </div>
          </div>
          {openEndCallPopup && <Popup closable={false} onClose={()=>{setOpenEndCallPopup(false)}} containerClass={`p-0`}>
            <div className={"relative bg-white px-12 py-8 rounded-lg"}>
              <div className={"mb-2 lg:mt-1 text-xl font-semibold mr-6"}>
                Are you sure?
              </div>
              <p>You may not be able to join the same call again.</p>
              <div className={'flex mt-1 xs:mt-4 justify-center'}>
                <button onClick={() => {setOpenEndCallPopup(false)}}
                        className={'border-1 border-denim py-3 text-black flex-1 rounded-lg xs:py-2 mb-2 w-full max-w-[150px] mr-2'}>
                  Cancel
                </button>
                <button onClick={() => {endCall()}}
                        className={'border-1 border-denim py-3 text-black flex-1 rounded-lg xs:py-2 mb-2 w-full max-w-[150px]'}>
                  Okay
                </button>
              </div>
            </div>
          </Popup>}
          {openSwitchRoomPopup && <Popup closable={false} onClose={()=>{setOpenSwitchRoomPopup(false)}} containerClass={`p-0`}>
            <div className={"relative bg-white px-12 py-8 rounded-lg"}>
              <div className={"mb-2 lg:mt-1 text-xl font-semibold mr-6"}>
                Are you sure?
              </div>
              <p>You may not be able to join the same call again.</p>
              <div className={'flex mt-1 xs:mt-4 justify-center'}>
                <button onClick={() => {setOpenSwitchRoomPopup(false)}}
                        className={'border-1 border-denim py-3 text-black flex-1 rounded-lg xs:py-2 mb-2 w-full max-w-[150px] mr-2'}>
                  Cancel
                </button>
                <button onClick={() => {
                  switchRoom();
                  setOpenSwitchRoomPopup(false);
                }}
                        className={'border-1 border-denim py-3 text-black flex-1 rounded-lg xs:py-2 mb-2 w-full max-w-[150px]'}>
                  Okay
                </button>
              </div>
            </div>
          </Popup>}
        </>}
      </main>
    </Layout>
  )
}

export default RoomPage
